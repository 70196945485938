
import { defineComponent, PropType } from "@vue/runtime-core";
import { RouteParams } from "vue-router";

const CDropdown = defineComponent({
  name: "CDropdown",
  props: {
    active: { type: Boolean, default: false },
    items: { type: Array as PropType<DropdownItem[]>, required: true },
    fieldID: { type: String, required: true },
  },
  methods: {
    handleDropdownWidth() {
      const width = this.$el?.getClientRects().item(0)?.width;
      const dropdownMenuContent = this.$el?.querySelector(".dropdown-menu .dropdown-content");

      dropdownMenuContent?.style.setProperty("min-width", `${width >= 160 ? width : 160}px`);
    },
  },
  mounted() {
    this.handleDropdownWidth();
  },
});

export type DropdownItem = {
  label?: string;
  icon?: string;
  value?: string | number;
  to?: { name: string; params?: RouteParams; query?: { [key: string]: any } } | null;
  command?: () => void;
  href?: string;
  isActive?: boolean;
  isDivider?: boolean;
};

export default CDropdown;
